import React, { memo, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchMe, clearWorkshopStorage, setUser } from "shared";
import { AuthRest } from "./auth/SignInAuth";
import { CurrentUser } from "./context/UserContext";
import { getUser, getUserError } from "./stores/selectors";
import CookieConsent from "./app/Components/CookieConsent";
import { gtmAddLayer } from "./app/Utilities";
import { CkMessage, configCkMessage } from "./CkUI";
import { WheelLoader } from "./app/Components/Loaders";
import App from "./App";
import UnauthenticatedApp from "./UnauthenticatedApp";

const authRest = new AuthRest();

type AuthStateStatus = "AUTHENTICATED" | "UNAUTHENTICATED" | "LOADING";

const state = {
  LOADING: "LOADING",
  AUTHENTICATED: "AUTHENTICATED",
  UNAUTHENTICATED: "UNAUTHENTICATED",
  APPLEGALNOTICES: "APPLEGALNOTICES",
};

const AuthenticationHelper = () => {
  const [authState, setAuthState] = useState<AuthStateStatus>("LOADING");
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const error = useSelector(getUserError);

  /**
   * config all messages
   */
  configCkMessage();

  const loadUser = () => {
    dispatch(fetchMe()).then((result: any) => {
      const data = result.payload;
    });
  };

  const login = async () => {
    // Perform login request
    const {status, data:{user}, message} = await authRest
      .urlLogin()
      .then((response) => response);
    // let's check if there are some error
    if (status === false) {
      CkMessage({ type: "error", text: message });
      setAuthState("UNAUTHENTICATED");
    } else {
      if (user.isNew) {
        gtmAddLayer({
          User_ID: user.id.toString(),
          account_type: "Workshop_user",
          event: "user_register",
        });
      } else {
        let user_type = "";
        if (user.userRoles.length > 0) {
          switch (user.userRoles[0].roleCode) {
            case "OWN":
              user_type = "Owner";
              break;
            case "ADM":
              user_type = "Administrator";
              break;

            default:
              user_type = "Mechanic";
              break;
          }
        }
        // GOOGLE TAG
        gtmAddLayer({
          User_ID: user.id,
          account_type: "Workshop_user",
          workshop_ID:
            user.userRoles.length > 0 ? user.userRoles[0].workshopId : "",
          workshop_user_type: user_type,
          event: "user_login",
        });
      }
      setAuthState("AUTHENTICATED");
      dispatch(setUser(user));
    }
  };

  const checkForErrors = async () => {
    const errors = await authRest.lookForErrors();
    if (errors.status === false)
      CkMessage({ type: "error", text: errors.message });
    setAuthState("UNAUTHENTICATED");
  };

  const checkForStoredSession = async () => {
    // request service to check if there is any stored and valid session
    const {
      status,
      data: { user },
    } = await authRest.lookForActiveSession();
    if (status === false) {
      setAuthState("UNAUTHENTICATED");
    } else {
      setAuthState("AUTHENTICATED");
      dispatch(setUser(user));
    }
  };

  const logout = () => {
    clearWorkshopStorage();
    setAuthState("UNAUTHENTICATED");
    authRest.logout();
    history.push("/");
  };

  const AuthHandler = () => {
    try {
      // window.addEventListener("load", () => {
      // Check if there is any hash
      if (window.location.hash !== "") {
        // Check url if there is a session token
        if (window.location.hash.indexOf("access_token") !== -1) {
          login();
        }
        // Check url if there is a session token
        if (window.location.hash.indexOf("error") !== -1) {
          checkForErrors();
        }
      } else {
        // Check if there is a stored session
        checkForStoredSession();
      }
      // });
    } catch (e) {
      console.log("addEventListener faild: ", e);
    }
  };

  const updateUserRoles = useCallback(() => {
    dispatch(fetchMe());
  }, [dispatch, user]);

  const redirectUserToHome = useCallback(() => {
    if (!!user === false) return false;
    if (user!.userRoles.length > 0) {
      if (
        user!.userRoles.find((role) => role.roleCode === "OWN") === undefined
      ) {
        return true;
      } else {
        if (
          user!.userRoles.find((role) => role.roleCode === "OWN")!.isConfigured
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }, [user]);

  useEffect(() => {
    if (error[0] === true) {
      if (error[1] && error[1].split("|")[0] == "Coworker") {
        console.log("User is exist");
      } else {
        logout();
      }
    }
  }, [error]);

  useEffect(() => {
    const root = document.querySelector(".root");
    if (authState === "AUTHENTICATED") {
      root.classList.add("authenticated");
    } else {
      root.classList.remove("authenticated");
    }
  }, [authState]);

  useEffect(() => {
    AuthHandler();
  }, []);

  if (
    authState === state.LOADING ||
    (authState === state.AUTHENTICATED && !!user === false)
  )
    return <WheelLoader mode="initial-loader" />;

  return (
    <CurrentUser.Provider value={user || undefined}>
      <CookieConsent />

      {authState === state.UNAUTHENTICATED && <UnauthenticatedApp />}

      {authState === state.AUTHENTICATED && (
        <>
          <App
            updateUserRoles={updateUserRoles}
            redirectUserToHome={redirectUserToHome}
            logout={logout}
          />
        </>
      )}
    </CurrentUser.Provider>
  );
};

export default memo(AuthenticationHelper);
