import React, { FC, useEffect, useState } from "react";
import { Button, Modal, Checkbox } from "antd";
import {
  useCookie,
  deleteAllCookies,
  loadGoogleTagManager,
} from "../../Utilities";
import { CKCloseIcon } from "../../../assets/SvgIcons";
import "./styles.css";
import { CkModal, CkButton, CkCheckbox } from "../../../CkUI";

interface ICookieConsentProps {}
// All cookie consents will have this value prepended
export const cookieConsentPrependKey: string = "ck-cookie-consent";
export enum CookiesKeys {
  version = "ck-cookie-consent-version",
  analytics = "ck-cookie-consent-analytics",
  marketing = "ck-cookie-consent-marketing",
  userAction = "ck-cookie-consent-user-action",
}

/**
 * This will be the version of the Current Cookie Consent
 * Is used to know the current version accepted by the user
 * If the cookie stored have a diferent version, banner will be force to be shown
 * Type string because when we store it with cookies, the value y retrieved as string.
 * const cookieConsentVersion :string = "2";
 * Value must be change in the enum
 * cookie version 3 -> User can't reject cookies, only accept them. User's can't interact unless cookies have been accepted.
 */
export enum CookiesDefaults {
  version = "3",
  analytics = "true",
  marketing = "true",
  userAction = "false",
}
/**
 * Posible cookies:
 * version -> cookie consent version
 * analytics -> if user allows analytics cookies
 * marketing -> if user allows marketing cookies
 * user-action -> if user have choosen
 */

const CookieConsent: FC<ICookieConsentProps> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [version, setVersion] = useCookie<string>(
    CookiesKeys.version,
    CookiesDefaults.version
  );
  const [analytics, setAnalytics] = useCookie<string>(
    CookiesKeys.analytics,
    CookiesDefaults.analytics
  );
  const [marketing, setMarketing] = useCookie<string>(
    CookiesKeys.marketing,
    CookiesDefaults.marketing
  );
  const [userAction, setUserAction] = useCookie<string>(
    CookiesKeys.userAction,
    CookiesDefaults.userAction
  );
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

  const onChange = (value: string) => {
    if (value === "analytics")
      setAnalytics(analytics === "true" ? "false" : "true");
    if (value === "marketing")
      setMarketing(marketing === "true" ? "false" : "true");
  };

  const applySettings = () => {
    /* 
    document.querySelector('body')!.style.overflow = "unset";
    document.querySelector('body')!.style.height = "unset"; 
    */
    if (analytics === "true") {
      loadGoogleTagManager();
    }
  };

  const acceptAll = () => {
    setAnalytics("true");
    setMarketing("true");
    setUserAction("true");
    applySettings();
  };

  const setDefaults = () => {
    setAnalytics(CookiesDefaults.analytics);
    setMarketing(CookiesDefaults.marketing);
    setUserAction(CookiesDefaults.userAction);
  };

  const forceCookies = () => {
    setVersion(version);
    setAnalytics(analytics);
    setMarketing(marketing);
    setUserAction(userAction);
  };

  const handleShowSettings = () => setShowSettingsModal(true);
  const handleHideSettings = () => setShowSettingsModal(false);
  const handleSaveSettings = () => {
    setUserAction("true");
    setShowSettingsModal(false);
    applySettings();
  };

  useEffect(() => {
    // If user have already make a choice in the past, apply settings
    userAction === "true" && applySettings();

    // Check if current version is different from stored version
    if (version !== CookiesDefaults.version && userAction === "true") {
      deleteAllCookies().then(setDefaults);
    }
    // Check if there are some cookies that should not been there
    if (userAction === "false") {
      deleteAllCookies().then(forceCookies);
    }

    setLoading(false);
  }, []);

  const openPrivacy = () => {
    window.open(
      "https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing",
      "_blank"
    );
  };

  if (loading === true) return null;
  if (userAction === "true") return null;

  return (
    <>
      {/* Settings modal  */}
      <CkModal
        className="cookie-consent-modal"
        zIndex={5000}
        title={"Configuración de Cookies"}
        open={showSettingsModal}
        onCancel={handleHideSettings}
        actionButtonsDirection={{
          responsive: "column",
          desktop: "column",
        }}
        primaryAction={{
          label: "Guardar configuración",
          onClick: handleSaveSettings,
        }}
        tertiaryAction={{
          label: "Política de cookies",
          onClick: openPrivacy,
        }}
      >
        <div className="constent-section">
          <CkCheckbox
            noMargin
            checkboxProps={{
              checked: true,
              disabled: true,
            }}
          />
          <h2>Cookies esenciales del sitio</h2>
          <p>
            Son las cookies necesarias para que un sitio web sea utilizable,
            permitiendo funciones básicas como la navegación por las páginas, y
            el acceso a áreas seguras del sitio web. El sitio web no puede
            funcionar correctamente sin estas cookies.
          </p>
          <p className="allways-active">Siempre activas</p>
        </div>

        <div className="constent-section">
          <CkCheckbox
            checkboxProps={{
              defaultChecked: analytics === "true",
              checked: analytics === "true",
              onChange: () => onChange("analytics"),
            }}
          />
          <h2>Cookies de analítica web</h2>
          <p>
            Utilizamos cookies de análisis para ayudarnos a medir la manera en
            cómo interactúa cada usuario con el contenido del sitio web. Esto
            nos ayuda a personalizar nuestros sitios web y nuestras
            aplicaciones, con el fin de mejorar la experiencia de todos los
            usuarios.
          </p>
        </div>

        <div className="constent-section">
          <CkCheckbox
            noMargin
            checkboxProps={{
              defaultChecked: marketing === "true",
              checked: marketing === "true",
              onChange: () => onChange("marketing"),
            }}
          />
          <h2>Cookies de preferencias</h2>
          <p>
            Las cookies de preferencias utilizan piezas de datos para dirigirse
            adecuadamente a diferentes grupos de usuarios, en función del
            comportamiento que tienen durante su navegación. Utilizamos estas
            cookies para poder mostrar anuncios que realmente conecten con los
            intereses de cada usuario a lo largo de su experiencia en el sitio
            web.
          </p>
        </div>
      </CkModal>

      {/* Notice modal  */}
      <CkModal
        className="consent-banner-modal"
        zIndex={5000}
        open={userAction === "false" && showSettingsModal === false}
        onOk={() => {}}
        onCancel={() => {}}
        closable={false}
        actionButtonsDirection={{
          responsive: "column",
          desktop: "column",
        }}
        primaryAction={{
          label: "Entendido",
          onClick: acceptAll,
        }}
        tertiaryAction={{
          label: "Más información",
          onClick: handleShowSettings,
        }}
      >
        <p>En esta página usamos cookies para mejorar tu experiencia.</p>
      </CkModal>
    </>
  );
};
export default CookieConsent;
