import React, { useEffect, memo, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "./stores/selectors";
import { getWorkshops } from "./stores";
import { AccountSetup } from "./Screens/AccountSetup/AccountSetup";
import { Dashboard } from "./Screens/Dashboard/Dashboard";
import { Workshop } from "shared/src/data-models/Workshop";
import WorkshopFormPage from "./Screens/WorkshopFormPage";
import TermsAndConditionsPage from "./Screens/TermsAndConditionsPage";
import { WheelLoader } from "./app/Components/Loaders";
import NotFoundPage from "./Screens/NotFoundPage";

const MemoizedApp = ({
  redirectUserToHome,
  logout,
  updateUserRoles,
}: {
  redirectUserToHome: Function;
  logout: Function;
  updateUserRoles: Function;
}) => {
  const user = useSelector(getUser);
  const workshops: Workshop[] = useSelector(getWorkshops);

  const history = useHistory();
  const location = useLocation();

  const [stopLoop, setStopLoop] = useState<boolean>(false);
  const [isMec, setIsMec] = useState<boolean>();

  const isConfigured = () => {
    if (workshops) {
      let someEmail: any[] = [];
      someEmail = workshops.filter((ws) => ws.email.length > 0);

      if (someEmail.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (workshops && location && stopLoop == false) {
      if (location.pathname === "/configura-tu-taller") {
        isConfigured() == true
          ? history.replace("/citas")
          : console.log("Configure workshop");
      }
    }
  }, [workshops]);

  useEffect(() => {
    if (stopLoop == true) {
      window.location.reload();
    }
  }, [stopLoop]);

  useEffect(() => {
    if (user === null) return;
    setIsMec(
      user?.userRoles.find((role) => role.roleCode === "MEC") !== undefined
    );
  }, [user]);

  if (isMec === undefined) return <WheelLoader mode="initial-loader" />;

  // FIXME: To reenable the application remove the commented components below
  return (
    <Switch>
      {/*   <Route exact path={"/terminos"}> */}
      {/*     <TermsAndConditionsPage */}
      {/*       accept={() => {}} */}
      {/*       decline={() => { */}
      {/*         history.replace("/"); */}
      {/*       }} */}
      {/*       showButtons={false} */}
      {/*     /> */}
      {/*   </Route> */}
      {/*   <Route exact path="/nuevo-taller-nueva-cuenta"> */}
      {/*     <WorkshopFormPage /> */}
      {/*   </Route> */}
      {/*   <Route exact path="/configura-tu-taller"> */}
      {/*     <AccountSetup */}
      {/*       redirectUserToHome={redirectUserToHome} */}
      {/*       initialState={0} */}
      {/*       logout={logout} */}
      {/*       updateRoles={updateUserRoles} */}
      {/*       fromWS={false} */}
      {/*       isMec={isMec} */}
      {/*       setStopLoop={setStopLoop} */}
      {/*     /> */}
      {/*   </Route> */}
      {/*   <Route exact path={["/:menu", "/:menu/:id", "/:menu/:category/:id"]}> */}
      {/*     <Dashboard logout={logout} /> */}
      {/*   </Route> */}
      {/*   <Route exact path="/"> */}
      {/*     {redirectUserToHome() ? ( */}
      {/*       <Redirect to={isMec ? "/citas" : "/tablero"} /> */}
      {/*     ) : ( */}
      {/*       <Redirect to="/configura-tu-taller" /> */}
      {/*     )} */}
      {/*   </Route> */}
      <Route path="/404">
        <NotFoundPage />
      </Route>
      <Redirect to="/404" />
      <Redirect to="/" />
    </Switch>
  );
};

export default memo(MemoizedApp);
