import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { UsesOfCarKer } from "./Screens/MobilContigo LandingPage/UsesOfCarKer";
import { Disclaimer } from "./Screens/LandingPage/Disclaimer";
import { LoginPage } from "./Screens/LoginPage/LoginPage";
import { Terms } from "./Screens/LandingPage/Terms";
import { LandingPage } from "./Screens/LandingPage";
import NotFoundPage from "./Screens/NotFoundPage";

import "./UnauthenticatedApp.css";

const UnauthenticatedApp = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // FIXME: To reenable the application remove the commented components below
  return (
    <Switch>
      <Route exact path="/">
        {/* Remove the div below and uncomment the LandingPage to enable the application */}
        <div className="closure-notice-page banner-display" />
        {/* <LandingPage /> */}
      </Route>
      {/* <Route exact path="/login"> */}
      {/*   <LoginPage /> */}
      {/* </Route> */}
      {/* <Route exact path="/registro"> */}
      {/*   <LoginPage defaultBox="register" /> */}
      {/* </Route> */}
      {/* <Route path="/terminos"> */}
      {/*   <Terms /> */}
      {/* </Route> */}
      {/* <Route path="/avisos-legales-CarKerApp"> */}
      {/*   <UsesOfCarKer /> */}
      {/* </Route> */}
      {/* <Route path="/usos-de-carker-CarKerApp"> */}
      {/*   <Disclaimer /> */}
      {/* </Route> */}
      <Route path="/404">
        <NotFoundPage />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default UnauthenticatedApp;
