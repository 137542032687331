import React, { FC } from "react";
import { WheelIcon2 } from "../../../../assets/SvgIcons";
import "./style.css";

interface IProps {
  spinning?: boolean;
  mode?: "initial-loader" | "full-page" | "cover-container"
}
const WheelLoader: FC<IProps> = ({
  spinning = true,
  mode = "full-page"
}) => {
  return (
    <div className={["wheel-loader", mode, ...(spinning ? ["spinning"] : [""])].join(" ")}>
      <WheelIcon2 className="wheel-icon" />
    </div>
  )
}

export default WheelLoader;
