import React from "react";
import { Link } from "react-router-dom";
import CarImage from "./404car.png";
import "./styles.css";

const NotFoundPage = () => (
  <section className="page-container not-found-page">
    <h1>Lo sentimos, no encontramos la página que buscas.</h1>
    <img width={480} height={480} src={CarImage} alt="Lo sentimos, no encontramos la página que buscas." />
    <Link to="/" className="ant-btn ant-btn-link --custom --underlined" type="link">
      Volver a la página principal
    </Link>
  </section>
)

export default NotFoundPage;
